<template>
    <div>
        <el-form style="margin-top: 20px" :inline="true" class="demo-form-inline">
            <el-form-item label="状态">
                <el-select v-model="planStatus" placeholder="请选择" clearable>
                    <el-option label="待完成" :value="0">
                    </el-option>
                    <el-option label="已取消" :value="1">
                    </el-option>
                    <el-option label="已完成" :value="2">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="时间">
                <el-date-picker v-model="timeRange" type="daterange" @change="changeTime" clearable
                    value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button @click="search" style="color: #ffffff; background-color: #ff7e00">查询</el-button>
                <el-button @click="openAddPlan" style="color: #ffffff; background-color: #ff7e00">新增</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="tableData">
            <el-table-column prop="user_name" label="用户"> <template v-slot="{ row }">
                    <div class="flex">
                        <link-to v-if="row.type == 1" :to="{ name: 'userDetails', params: { id: row.user_id } }">{{
                            row.user_name }}</link-to>
                        <link-to v-if="row.type == 2" :to="{ name: 'mecDetails', query: { mecID: row.user_id } }">{{
                            row.user_name }}</link-to>
                        <span v-if="row.type == 3">{{ row.user_name }}</span>
                        <el-tag size="small" type="success" v-if="row.type == 1">用户</el-tag>
                        <el-tag size="small" v-if="row.type == 2">机构</el-tag>
                    </div>
                </template></el-table-column>
            <el-table-column prop="admin_name" label="顾问"></el-table-column>
            <el-table-column label="计划内容">
                <template v-slot="{ row }">
                    <div class="ell" @click="planContentShow = true; planContent = row.content">{{ row.content }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="状态" width="100px">
                <template v-slot="{ row }">
                    <el-tag v-if="row.status == 0" type="info">待完成</el-tag>
                    <el-tag v-if="row.status == 1" type="warning">已取消</el-tag>
                    <el-tag v-if="row.status == 2">已完成</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="任务开始时间">
                <template v-slot="{ row }">
                    <div class="flex">
                        <P>{{ row.start_time }}</P>
                        <el-tag size="small" v-if="row.has_order">有订单</el-tag>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template v-slot="{ row }">
                    <div v-if="row.status != 2">
                        <el-button type="success" size="small" @click="openRecord(row)">完成计划</el-button>
                        <el-button v-if="row.add_admin_id == manager.id && row.status === 1" type="primary" size="small"
                            @click="cancelPlan(row, 0)">开启</el-button>
                        <el-button v-if="row.add_admin_id == manager.id && row.status === 1" type="primary" size="small"
                            @click="cancelPlan(row, 1)">取消</el-button>
                        <el-button v-if="row.add_admin_id == manager.id" type="warning" size="small"
                            @click="openUpdatePlan(row)">修改</el-button>
                        <el-button v-if="row.add_admin_id == manager.id" type="danger" size="small"
                            @click="deletePlan(row)">删除</el-button>
                    </div>
                    <el-button v-else type="success" size="small" @click="toPlanDetails(row)">详情</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页 -->
        <paging-fy @currentPageChange="currentPageChange" :currentPage="currentPage" :total="total"></paging-fy>

        <!-- 回访计划 -->
        <el-dialog :visible.sync="dialogVisible" width="30%" title="添加计划" :destroy-on-close="true">
            <el-form label-width="80px">
                <el-form-item label="回访对象" required>
                    <el-radio v-model="planForm.type" :label="1">注册用户</el-radio>
                    <el-radio v-model="planForm.type" :label="2">入驻机构</el-radio>
                    <el-radio v-model="planForm.type" :label="3">其他(待入驻)</el-radio>
                </el-form-item>
                <el-form-item label="访问用户" required v-if="planForm.type == 1">
                    <user-select @change="changeUser" ref="user" :placeholder="planForm.user_name"></user-select>
                </el-form-item>
                <el-form-item label="访问机构" required v-if="planForm.type == 2">
                    <mechanism-select @change="changeMec" ref="user" :placeholder="planForm.user_name"></mechanism-select>
                </el-form-item>
                <el-form-item label="访问对象" required v-if="planForm.type == 3">
                    <el-input v-model="planForm.user_name" placeholder="请输入访问对象"></el-input>
                </el-form-item>
                <el-form-item label="计划内容" required>
                    <el-input type="textarea" :rows="2" v-model="planForm.content" placeholder="请输入计划内容"></el-input>
                </el-form-item>
                <el-form-item label="执行人" ref="admin" required v-if="manager.type == 'admin'">
                    <div class="flex">  <admin-select ref="as" @change="changeConsultant" :placeholder="planForm.admin_name"></admin-select>
            <el-button type="text" @click="changeSelf">本人</el-button></div>
                </el-form-item>
                <el-form-item label="开始时间" required>
                    <el-date-picker v-model="planForm.start_time" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
                        placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间">
                    <el-date-picker v-model="planForm.end_time" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
                        placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="订单">
                    <el-switch v-model="planForm.has_order" :active-value="1" :inactive-value="0" active-text="有订单"
                        inactive-text="无">
                    </el-switch>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button v-if="mode == 'add'" style="background-color: #ff7e00; color: #ffffff" @click="addplan">确
                    定</el-button>
                <el-button v-else style="background-color: #ff7e00; color: #ffffff" @click="updateplan">修 改</el-button>
            </span>
        </el-dialog>

        <!-- 完成计划 -->
        <el-dialog :visible.sync="recordShow" width="30%" title="完成计划">
            <el-form label-width="80px">
                <el-form-item label="联系方式" required>
                    <el-radio v-model="recordForm.contact_type" :label="1">电话</el-radio>
                    <el-radio v-model="recordForm.contact_type" :label="2">微信</el-radio>
                </el-form-item>
                <el-form-item label="联系内容" required>
                    <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 6 }" placeholder="请输入内容"
                        v-model="recordForm.contact_content">
                    </el-input>
                </el-form-item>
                <el-form-item label="联系时间" required>
                    <el-date-picker v-model="recordForm.contact_time" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
                        placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="订单金额" required v-if="curPlan.has_order">
                    <el-input placeholder="请输入订单金额" v-model="recordForm.order_price">
                    </el-input>
                </el-form-item>
                <el-form-item label="订单完成图片" required v-if="curPlan.has_order">
                    <images-uploader @getImgUrl="handlerOrderImg"></images-uploader>
                </el-form-item>
                <el-form-item label="订单备注" v-if="curPlan.has_order">
                    <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 6 }" placeholder="请输入订单备注"
                        v-model="recordForm.order_remark">
                    </el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button style="background-color: #ff7e00; color: #ffffff" @click="addRecord">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog title="计划内容" :visible.sync="planContentShow" width="30%">
            <p>
                {{ planContent }}
            </p>
        </el-dialog>
    </div>
</template>
  
<script>
import adminSelect from "@/components/select/adminSelect.vue"

export default {
    components: {
        adminSelect
    },
    props:{
        user_id:{
            type:[Number,String],
        },
        type:{
            type:Number
        }
    },
    data() {
        return {
            id:'',
            nav: { firstNav: "顾问管理中心", secondNav: "任务详情" },
            activeName: 'plan',
            tableData: [],
            currentPage: 1,
            total: 0,
            name: '',
            manager: JSON.parse(localStorage.getItem("managerInfo")),
            dialogVisible: false,
            planForm: {},
            recordForm: {},
            recordShow: false,
            curPlan: {},
            mode: 'add',
            user_name: "",
            planStatus: "",
            planStartTime: '',
            planEndTime: '',
            timeRange: [],
            planContentShow: false,
            planContent: '',
            recordContentShow: false,
            recordContent: ''
        };
    },
    created() {
        this.getPlanList()
    },
    methods: {
        changeSelf(){
      this.$refs.as.selected_admin= this.manager.name
      this.planForm.admin_name = this.manager.name
      this.planForm.admin_id = this.manager.id
    },
        toPlanDetails(row) {
            this.$router.push({
                name: 'consultantPlanDetails',
                params: {
                    id: row.id
                }
            });
        },
        search() {
            this.currentPage = 1
            this.getPlanList()
        },
        todetails(row) {
            this.$router.push({
                name: 'consultantDetails',
                params: {
                    id: row.id
                }
            });
        },
        changeTime(v) {
            if (v.length >= 2) {
                this.planStartTime = v[0]
                this.planEndTime = v[1]
            } else {
                this.planStartTime = new Date().Format("yyyy-MM-dd") + ' 00:00:00'
                this.planEndTime = new Date().Format("yyyy-MM-dd") + ' 23:59:59'
            }
        },
        getPlanList() {
            let data = {
                start_time: this.planStartTime || null,
                end_time: this.planEndTime || null,
                pageSize: 10,
                currentPage: this.currentPage,
                user_id: this.user_id || null,
                type: this.type || null,
                status: this.planStatus !== "" ? this.planStatus : null
            }
            if (this.manager.type == 'consultant') {
                data.admin_id = this.manager.id
            }
            let url = "/user/consultantPlan/queryManagerListPage";
            this.$axios
                .get(url, {
                    params: data,
                })
                .then((res) => {
                    this.tableData = res.data.data.rows
                    this.total = res.data.data.total
                });
        },
        getRecordList() {
            let data = {
                pageSize: 10,
                currentPage: this.currentPage,
                user_name: this.user_name || null,
            }
            if (this.manager.type == 'consultant') {
                data.admin_id = this.manager.id
            }
            let url = "/user/consultantContactRecord/queryManagerListPage";
            this.$axios
                .get(url, {
                    params: data,
                })
                .then((res) => {
                    this.tableData = res.data.data.rows
                    this.total = res.data.data.total
                });
        },
        currentPageChange(v) {
            this.currentPage = v
            this.getPlanList()
        },

        openRecord(plan) {
            this.curPlan = plan
            this.recordShow = true
        },
        addRecord() {
            if (this.checkRecordForm()) {
                let data = {
                    admin_id: this.curPlan.admin_id,
                    admin_name: this.curPlan.admin_name,
                    user_id: this.curPlan.user_id,
                    user_name: this.curPlan.user_name,
                    plan_id: this.curPlan.id,
                    contact_content: this.recordForm.contact_content,
                    contact_time: this.recordForm.contact_time,
                    contact_type: this.recordForm.contact_type,
                    order_price: this.recordForm.order_price,
                    order_img: this.recordForm.order_img,
                    order_remark: this.recordForm.order_remark
                }
                this.$axios({
                    url: '/user/consultantContactRecord/insert',
                    data: data,
                    method: 'post'
                }).then(res => {
                    this.$notify.success('计划已完成')
                    this.recordForm = {}
                    this.recordShow = false
                    this.getPlanList()
                })
            }
        },


        openAddPlan() {
            this.dialogVisible = true
            this.mode = 'add'
            setTimeout(() => {
                console.log(this.$refs);
                this.$refs.user.clear()
                this.$refs.admin.clear()
            }, 200)

        },
        openUpdatePlan(plan) {
            this.mode = 'edit'
            this.dialogVisible = true
            this.planForm = plan
            this.$refs.user.clear()
            this.$refs.admin.clear()
        },
        cancelPlan(plan, status) {
            let t = status == 1 ? '取消' : '开启'
            this.$confirm(`·确认${t}回访计划?`, `${t}计划`).then(() => {
                this.$axios({
                    url: '/user/consultantPlan/update',
                    data: {
                        status,
                        id: plan.id
                    },
                    method: 'post'
                }).then(res => {
                    this.$message(`·${t}成功`)
                    this.getPlanList()
                })
            })
        },
        deletePlan(plan) {
            this.$confirm("确认删除回访计划？", "删除计划").then(() => {
                this.$axios({
                    url: '/user/consultantPlan/delete',
                    params: {
                        id: plan.id
                    },
                }).then(res => {
                    this.$message('任务已删除')
                    this.getPlanList()
                })
            })
        },
        changeUser(user) {
            this.planForm.user_id = user.user_id
            this.planForm.user_name = user.nick_name
        },
        changeMec(mec) {
            this.planForm.user_id = mec.id
            this.planForm.user_name = mec.mechanism_name
        },

        changeConsultant(admin) {
            this.planForm.admin_id = admin.id
            this.planForm.admin_name = admin.name
        },
        handlerOrderImg(v) {
            this.recordForm.order_img = v
        },
        addplan() {
            if (this.manager.type == 'consultant') {
                this.planForm.admin_id = this.manager.id
                this.planForm.admin_name = this.manager.name
            }
            if (this.checkForm()) {
                this.planForm.add_admin_id = this.manager.id
                this.planForm.add_admin_name = this.manager.name
                this.$axios({
                    url: '/user/consultantPlan/insert',
                    data: this.planForm,
                    method: 'post'
                }).then(res => {
                    this.$message("添加成功")
                    this.planForm = {}
                    this.dialogVisible = false
                    this.getPlanList()
                })
            }
        },
        updateplan() {
            if (this.manager.type == 'consultant') {
                this.planForm.admin_id = this.manager.id
                this.planForm.admin_name = this.manager.name
            }
            if (this.checkForm()) {
                let data = {
                    id: this.planForm.id,
                    user_id: this.planForm.user_id,
                    user_name: this.planForm.user_name,
                    admin_id: this.planForm.admin_id,
                    admin_name: this.planForm.admin_name,
                    content: this.planForm.content,
                    start_time: this.planForm.start_time,
                    end_time: this.planForm.end_time
                }
                this.$axios({
                    url: '/user/consultantPlan/update',
                    data: data,
                    method: 'post'
                }).then(res => {
                    this.planForm = {}
                    this.dialogVisible = false
                    this.getPlanList()
                })
            }
        },
        checkForm() {
            if (!this.planForm.user_name) {
                this.$message("请选择访问用户")
                return false
            }
            if (!this.planForm.admin_name) {
                this.$message("请选择执行顾问")
                return false
            }
            if (!this.planForm.content) {
                this.$message("请输入内容")
                return false
            }
            if (!this.planForm.start_time) {
                this.$message("请选择开始时间")
                return false
            }
            return true
        },
        checkRecordForm() {
            if (!this.recordForm.contact_type) {
                this.$message("请选择联系方式")
                return false
            }
            if (!this.recordForm.contact_time) {
                this.$message("请选择联系时间")
                return false
            }
            if (!this.recordForm.contact_content) {
                this.$message("请输入内容")
                return false
            }
            if (this.curPlan.has_order == 1 && !this.recordForm.order_img) {
                this.$message("请上传订单图片，如合同，收款凭证")
                return false
            }
            if (this.curPlan.has_order == 1 && !this.recordForm.order_price) {
                this.$message("请输入订单金额")
                return false
            }
            return true
        },
    },
};
</script>
  
<style lang="less">
.ell {
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
        color: #0090ff;
    }
}
</style>
  