/**
 * value:订单类型
 * name：类型名称
 * income:是否为收入，true收入订单，false支出订单
 */


export const orderType = [
  {
    value: "study_card",
    label: "课程",
    income:true
  },
  {
    value: "member",
    label: "会员卡",
    income:true
  },
  {
    value: "point",
    label: "权益点",
    income:true
  },
  {
    value: "consume",
    label: "消费金",
    income:true
  },
  {
    value: "coupon",
    label: "优惠券",
    income:true
  },
  {
    value: "coach_need",
    label: "教练课包",
    income:true
  },
  {
    value: "need_bond",
    label: "需求保证金",
    income:true
  },
  {
    value: "venue",
    label: "场馆服务",
    income:true
  },
  {
    value: "experience",
    label: "会员续费",
    income:true
  },
  {
    value: "商户",
    label: "休闲生活",
    income:true
  },
  {
    value: "attestation_coach",
    label: "认证教练",
    income:true
  },
  {
    value: "bond_pay_full",
    label: "保证金充值",
    income:true
  },
  {
    value: "bond_pay_one",
    label: "上架课程保证金",
    income:true
  },
  {
    value: "coach_ip",
    label: "教练IP服务",
    income:true
  },
  {
    value: "change_course",
    label: "换课",
    income:true
  },
  {
    value: "need_one_pay",
    label: "需求课程单节付订单",
    income:true
  },
  {
    value: "talent_card_exchange",
    label: "天赋卡订单",
    income:true
  },
  {
    value: "结算",
    label: "结算",
    income:false
  },
  {
    value: "transfer",
    label: "提现",
    income:false
  },
  {
    value: "refund",
    label: "退款",
    income:false
  },
  {
    value: "mechanism_bond_withdraw",
    label: "机构保证金提现",
    income:false
  },
  {
    value: "coach_logout",
    label: "教练保证金提现",
    income:false
  },
  {
    value: "商户券核销",
    label: "商户券核销",
    income:false
  },
  {
    value: "场馆结算",
    label: "场馆结算",
    income:false
  },
  {
    value: "教练需求结算",
    label: "教练需求结算",
    income:false
  },
  {
    value: "机构需求结算",
    label: "机构需求结算",
    income:false
  },
  {
    value: "studycard_single",
    label: "单节付购买课程",
    income:true
  },
  {
    value: "course_single_appo",
    label: "单节付订单(预约)",
    income:true
  },
  {
    value: "course_single_fixed",
    label: "单节付订单(固定)",
    income:true
  },
  {
    value: "chat_card",
    label: "购买畅聊卡",
    income:true
  },
  {
    value: "coach_order_bond",
    label: "教练接单保证金",
    income:true
  },
  {
    value: "create_union",
    label: "创建公会",
    income:true
  }
];

export function getorderType(type) {
  let res = orderType.filter((item) => {
    return (item.value == type);
  })[0] || {
    value: type,
    label: type,
    income:true,
    notQuery:true
  };
  return res;
}

